import React, { useState, useEffect } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { Form, Col } from 'react-bootstrap'
import Select from 'react-select';
import _ from 'lodash'
import { Row, Button} from './styles/JobModal.styled'
import './styles/InterviewQuestionModal.scss'
import { makeRequest } from '../RequestAssist/RequestAssist'
import PencilIcon from '../../../../assets/images/icons/pencil-icon-v2.svg'
import DeleteIcon from '../../../../assets/images/icons/trash-icon-v2.svg'



function InterviewQuestionModal({ onClose, job ,allowUpdate, currentUser }) {
    const [category, setCategory] = useState('')
    const [defaultCategory, setDefaultCategory] = useState({})
    const [question, setQuestion] = useState('')
    const [validationError, setValidationError] = useState(false)
    const [errorObj, setErrorObj] = useState({})
    const [allowToUpdate, setallowUpdate] = useState(!allowUpdate)
    const [questionList, setQuestionList] = useState({})
    const [questionObj, setQuestionObj] = useState({})
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [mode, setMode] = useState('add')
  
    const categoryType = [
        { value: 'Technical', label: 'Technical' },
        { value: 'Non-technical', label: 'Non-technical' }
    ];
    
    const checkValidation = () => {
        setValidationError(false)
        setErrorObj({})
        let isValid = true
        if(_.isEmpty(category?.trim())){
            setValidationError(true)
            setErrorObj({ ...errorObj, category: true });
            return false;
        }
        if(_.isEmpty(question?.trim())){
            setValidationError(true)
            setErrorObj({ ...errorObj, question: true });
            return false;
        }
        return isValid
    };

    const handleCategory = (event) => {
       setDefaultCategory(event)
       setCategory(event.value)
    };
    const handleQuestion = (event) => {
        setQuestion(event.target.value)
     };

    const handleQuestionSubmit = (e) => {
        if(checkValidation()){
            let URL = '/add_ai_interview_question'
            let method = 'post'
			if(mode == 'edit'){
				URL = `/update_ai_interview_question/${questionObj.id}`
                method = 'put'
			}
			const payload = new FormData()
			payload.append('question_param[job_id]', job.id)
			payload.append('question_param[category]', category)
			payload.append('question_param[question]', question)
            if(mode != 'edit'){
                payload.append('question_param[created_by]', currentUser.id)
            }

			//headers
			const CSRF_Token = document
				.querySelector('meta[name="csrf-token"]')
				.getAttribute('content')
			const headers = {
				headers: {
						'content-type': 'application/json',
						'X-CSRF-Token': CSRF_Token,
				},
			}
			makeRequest(URL,method, payload, headers, {
				contentType: 'application/json',
				loadingMessage: 'Submitting...',
			}).then((res) => {
				if(res.data.success){
                    fetchData()
                    setQuestion('')
                    setValidationError(false)
                    setErrorObj({})
                    setMode('add')
				}
			}) 
        }
    }

    const colourStyles = {
        control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#F6F7FC',minWidth:'12em',maxWidth:'12em',minHeight:'50px' })
      };


      const fetchData = () => {
        const url = `/ai_interviews/${job.id}`
        makeRequest(url, 'get',  {}, {   
          contentType: 'application/json',
          createSuccessMessage: (response) => response.data.message,
          onSuccess: (res) => {
                if(res.data.success){
                    setQuestionList(res.data.list)
                }
          },
        })
      }

      const onEditClick = (obj) => {
        setQuestionObj(obj)
        setQuestion(obj.question)
        setCategory(obj.category)
        setDefaultCategory({ value: obj.category, label: obj.category })
        setMode('edit')
      }

      const onDeleteClick = (obj) => {
        setQuestionObj(obj)
        setShowDeleteConfirmation(true)
      }

      const onDeleteAIQuestion = () => {
        if(questionObj != {} && questionObj != null){
            const url = `/ai_interviews/${questionObj.id}`
            makeRequest(url, 'delete',  {}, {   
            contentType: 'application/json',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (res) => {
                    if(res.data.success){
                        fetchData()
                    }
            },
            })
        }
      }

      useEffect(() => {
        fetchData()
      }, [])

    return(
            <>
                
                <Row>
                    <table className='table-centered-intererview'>
                        <thead>
                            <tr>
                                <th>Sr.</th>
                                <th>Category</th>
                                <th>Question</th>
                                { !allowToUpdate &&
                                    <th>Action</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            { questionList.length > 0 && questionList && questionList.map((ques, index) => (
                                <tr>
                                    <td>{index + 1 }</td>
                                    <td>{ques.category}</td>
                                    <td className='left-td'>{ques.question}</td>
                                    { !allowToUpdate &&
                                        <td> <img src={PencilIcon} height={20} width={20} className='mr-2 hand-icon' onClick={()=> onEditClick(ques)}/>
                                            <img src={DeleteIcon}  height={19} width={19} className='hand-icon'  onClick={()=> onDeleteClick(ques)}/>
                                        </td>
                                    }
                                </tr>
                            ))}
                            { questionList.length == 0 &&
                                <tr>
                                    
                                    <td colSpan={ (!allowToUpdate) ? 4 : 3}>No record found</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </Row>
                { !allowToUpdate &&
                <>
                <Row>
                    <h5 className='ml-3'>{ mode == 'add' ? 'Add' : 'Update' } Question: </h5>
                </Row>
                <Row style={{minHeight: '145px'}}>
                    <Col xs={3} lg={3}>
                        <label>Category:</label>
                        <Select
                            name="category"
                            onChange={handleCategory} 
                            options={categoryType}
                            styles={colourStyles}
                            value={defaultCategory}
                        />
                        {  validationError && errorObj?.category &&
                            <div className='span-error-validate'>
                                <span>Required field!</span>
                            </div>
                        }
                            
                    </Col>
                    <Col xs={7} lg={7}>
                            <label>
                                Question:
                            </label>
                            <Form.Control
                                type="text"
                                name="question_title"
                                onChange={handleQuestion} 
                                style={{height: '50px'}}
                                value={question}
                            />
                            { validationError && errorObj?.question &&
                                <div className='span-error-validate'>
                                    <span>Required field!</span>
                                </div>
                                
                            }
                            
                    </Col>
                    <Col xs={2} lg={2}>
                        <Button type="button" onClick={(e) => handleQuestionSubmit(e)} style={{marginTop: '32px',marginLeft:'12px',lineHeight: '30px',width: '100px'}}>{ mode == 'add' ? 'Add' : 'Update' }</Button> 
                    </Col>
                </Row>
                </>
                 }
                {showDeleteConfirmation &&
                    <SweetAlert
                        title="Are you sure you want to delete?"
                        warning
                        showCancel
                        confirmBtnText="Yes, Make it!"
                        confirmBtnBsStyle="danger"
                        onConfirm={()=>(onDeleteAIQuestion(),setShowDeleteConfirmation(false))}
                        onCancel={()=>(setQuestionObj({}),setShowDeleteConfirmation(false))}
                        >
                    </SweetAlert>
                }
            </>
    )

}

export default InterviewQuestionModal
