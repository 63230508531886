// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".FilterAutocomplete-module__wrapper___1j3cc{height:10rem}\n", "",{"version":3,"sources":["/home/siva/ch-job-marketplace/releases/20240930080327/app/javascript/components/common/inputs/TagInput/style/FilterAutocomplete.module.scss"],"names":[],"mappings":"AAAA,4CACI,YAAa","file":"FilterAutocomplete.module.scss","sourcesContent":[".wrapper{\n    height: 10rem;\n}"]}]);
// Exports
exports.locals = {
	"wrapper": "FilterAutocomplete-module__wrapper___1j3cc"
};
module.exports = exports;
