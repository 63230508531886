// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".XButton-module__x___1x4us{padding:0;display:flex;background-color:transparent;color:#666;transition:all 150ms;transform:scale(0.8, 0.8);cursor:pointer}.XButton-module__x___1x4us:hover{transform:scale(1, 1);color:#333}\n", "",{"version":3,"sources":["/home/siva/ch-job-marketplace/releases/20240930080327/app/javascript/components/common/XButton/styles/XButton.module.scss"],"names":[],"mappings":"AAAA,2BACI,SAAU,CACV,YAAa,CACb,4BAA6B,CAC7B,UAAW,CACX,oBAAqB,CACrB,yBAA0B,CAC1B,cAAe,CAPnB,iCASQ,qBAAsB,CACtB,UAAW","file":"XButton.module.scss","sourcesContent":[".x {\n    padding: 0;\n    display: flex;\n    background-color: transparent;\n    color: #666;\n    transition: all 150ms;\n    transform: scale(0.8, 0.8);\n    cursor: pointer;\n    &:hover {\n        transform: scale(1, 1);\n        color: #333;\n    }\n}\n"]}]);
// Exports
exports.locals = {
	"x": "XButton-module__x___1x4us"
};
module.exports = exports;
