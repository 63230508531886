// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".ScheduledInterviwesPage-module__container___2sIAs{background:#ffffff;box-shadow:0px 4px 25px rgba(0,0,0,0.25);border-radius:50px;padding:3rem 4rem;margin:90px 50px}@media only screen and (min-device-width: 320px) and (max-device-width: 575px){.ScheduledInterviwesPage-module__container___2sIAs{border-radius:15px;padding:15px;margin:15px}.ScheduledInterviwesPage-module__newRow___17JkE{margin:0px !important}}\n", "",{"version":3,"sources":["/home/siva/ch-job-marketplace/releases/20240930080327/app/javascript/components/pages/ScheduledInterviwesPage/stryles/ScheduledInterviwesPage.module.scss"],"names":[],"mappings":"AAAA,mDACI,kBAAmB,CACnB,wCAA4C,CAC5C,kBAAmB,CACnB,iBAAkB,CAClB,gBACJ,CAAC,+EAGD,mDACI,kBAAmB,CACnB,YAAa,CACb,WAAY,CACf,gDAEG,qBAAqB,CACxB","file":"ScheduledInterviwesPage.module.scss","sourcesContent":[".container {\n    background: #ffffff;\n    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);\n    border-radius: 50px;\n    padding: 3rem 4rem;\n    margin: 90px 50px\n}\n\n@media only screen and (min-device-width : 320px) and (max-device-width : 575px){\n.container {\n    border-radius: 15px;\n    padding: 15px;\n    margin: 15px;\n}    \n.newRow{\n    margin:0px !important;\n}\n\n}"]}]);
// Exports
exports.locals = {
	"container": "ScheduledInterviwesPage-module__container___2sIAs",
	"newRow": "ScheduledInterviwesPage-module__newRow___17JkE"
};
module.exports = exports;
